:root{
    --homecolor:dark;
    --side-bar-color:#1a1c1e;
    --home-font-color:white;
    --home-shadow:#616367;
    --side-bar-link:#9c9ea2;
    --side-bar-link-hover-color:#303234;
    --head-section-color:#09155d;
}
*{
    font-family: 'Nunito', sans-serif;
}
html,body{
    height: 100vh;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
#root{
    height: 100%;
}
section{
    height: 100vh;
}
.my-logo{
    width: 32px;
}
h2{
    font-weight: 700;
    opacity: .6;
    color: var(--head-section-color);
}
h3{
    font-weight: 600;
}
.logo{
    font-weight: 800;
    font-size: 18px;
}
.home-page{
    background: rgb(9,21,93);
    background: linear-gradient(90deg, rgba(9,21,93,1) 2%, rgba(0,8,45,1) 69%, rgba(0,8,45,1) 100%);
}
header .desktop-navigation li{
    font-weight: 600;
    font-size: 16px;
    list-style: none;
}
header .desktop-navigation li a{
    text-decoration: unset;
    color: var(--home-font-color);
}
h1{
    max-width: 330.9px;
}
.home-text-under-h1{
    max-width: 353px;
    color: var(--home-font-color);
    font-weight: 400;
}
.home-learn-more{
    width: 141px;
    background-color: #010817;
    border-radius: 15px;
    font-size: 14px;
    box-shadow: .5px 1.2px .8px var(--home-shadow);
}
.language-row div{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.language-row div i{
    color: var(--home-font-color);
    font-size: 40px;
}
.side-bar-style{
    background-color: var(--side-bar-color);
}

.side-bar-close:hover{
background-color: red !important;
opacity: 0.7;
border-radius: 15px;
}

.side-bar-navigation li{
    list-style: none;
    padding: 5px 0px 5px 14px;
    border-radius: 10px;
    cursor: pointer;
}
.side-bar-navigation li:hover{
    background-color: var(--side-bar-link-hover-color);
}
.side-bar-navigation li i{
    margin-right: 8px;
}
.side-bar-navigation li a{
    text-decoration: unset;
    color: var(--side-bar-link);
    font-weight: 400;
    font-size: 15px;
}
.side-bar-indice{
    color: var(--home-shadow);
    font-weight: 400;
    font-size: 12px;
}
.side-bar-social-media li {
    list-style: none;
}
.side-bar-social-media li i{
    margin-right: 8px;
}
.side-bar-social-media li a{
    text-decoration: unset;
    color: var(--side-bar-link);
    font-weight: 400;
    font-size: 15px;
}
.alert-card-email{
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1046;
}

/* ABOUT ME  */
.about-me div h3{
    font-weight: 400;
    font-size: 30px;
}
.about-me div span{
    display: inline-block;
    font-weight: 400;
    max-width: 401px;
}
.about-me div img{
    width: 70% !important;
}
.skills-section span{
    display: inline-block;
    max-width: 330px;
}
.skills-swiper-compenent{
    width: 230px;
}
.skills-swiper-compenent span{
    font-weight: 800;
    color: var(--head-section-color);
    opacity: .8;
}
.skills-swiper-compenent ul{
    min-height: 170px;
}
.skills-swiper-compenent ul li{
    font-weight: 600;
}
.soft-skills-button{
    font-weight: 800;
    color: var(--head-section-color);
}
.soft-skills-section li{
    font-weight: 600;
    margin-bottom: 5px;
}
.projects-page div div ul li{
    opacity: .8;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.projects-page span{
    font-weight: 800;
    color: var(--head-section-color);
    opacity: .8;
}
.contactme-section div span{
    font-weight: 800;
    opacity: .8;
    color: var(--head-section-color);

}
.content-swiper-compenent{
    width: 250px;
}
.content-swiper-compenent ul{
    min-height: 170px;
}
.content-swiper-compenent ul li{
    font-weight: 600;
}
.content-swiper-compenent ul button{
    margin-top: 20px;
    background-color: var(--head-section-color);
    color: white;
    border: none;
    border-radius: 15px;
    padding-left: 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
    outline: none;
    padding-right: 1rem;
}
@media (min-width: 576px) { 
    .about-me div img{
        width: 60% !important;
    }
 }

@media (min-width: 768px) { 
    .about-me div img{
        width: 55% !important;
    }
 }

@media (min-width: 992px) { 
    .about-me div img{
        width: 100% !important;
    }
 }

@media (min-width: 1200px) { 
    .about-me div img{
        width: 100% !important;
    }
 }
